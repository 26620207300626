if ( window.history.replaceState ) {
    window.history.replaceState( null, null, window.location.href );
}

$(document).ready(function() {
    // sidebar menu highlight
    /*$(".nav-link-pd").on("click", function(e) {
        $(".nav-link-pd").css("color", "unset");
        $(".nav-link-pd").css("font-weight", "unset");

        var parent = $(this).parent().prev();

        if (parent.hasClass("nav-link-pd")) {
            parent.css({"color":"#c0392b", "font-weight" : "600"});
        }

        $(this).css({"color":"#c0392b", "font-weight" : "600"});
    });*/

    $(".video-play-button").click(function() {
        $(this).parent().children(":first-child").replaceWith(atob($(this).parent().attr("data-iframe")));
        $(this).remove();
    });

    $(".reload-vid-btn").click(function() {
        $(this).parent().children(":first-child").children(":first-child").replaceWith(atob($(this).parent().children(":first-child").attr("data-iframe")));
        $(this).parent().find("#play-video").remove();
    });

    $(".video-new-window").click(function() {
        openWindow($(this).parent().children(":first-child").attr("data-iframe"));
    });

    //Port-forwarding section
    const hostname = "elite.mydomain.com";
    const unknownIp = "X.X.X.X";
    const httpsPort = "443";
    const rtpPort = "8554";
    const natsPort = "4242";
    const dexId = "strix-elite";
    const dexSecret = "AOZqxZGHHt";
    const hostnameVpn = "vpn.mydomain.com";
    const rtspHttpPort = "8080";
    const rtspHttpUsername = "admin";
    const rtspHttpPassword = "admin";
    const licenseKey = "REPLACE_WITH_ACTIVATION_KEY";

    let portForwardingDisabledBtn = $("#port-forwarding-disabled");
    let portForwardingEnabledBtn = $("#port-forwarding-enabled");

    let natsChangePortConfigFile = $("#nats-change-port-config-file");
    let dontUse4243 = $("#dont-use-4243");

    let rtspCredentialsEnabledBtn = $("#rtsp-credentials-enabled");
    let rtspCredentialsDisabledBtn = $("#rtsp-credentials-disabled");

    let statusApiUrlBtn = $("#status-api-url");
    let gpsApiUrlBtn = $("#gps-api-url");
    let batteryApiUrlBtn = $("#battery-api-url");

    let hostnameOutput = $(".hostname-output");
    let publicIpOutput = $(".public-ip-output");
    let privateIpOutput = $(".private-ip-output");
    let rtpPortOutput = $(".rtp-port-output");
    let natsPortOutput = $(".nats-port-output");
    let dexIdOutput = $(".dex-id-output");
    let dexSecretOutput = $(".dex-secret-output");
    let httpsPortOutput = $(".https-port-output");
    let httpsPortNginxOutput = $(".https-port-nginx-output");
    //VPN
    let hostnameOutputVpn = $(".hostname-output-vpn");
    //RTSP
    let rtspIpOutput = $(".rtsp-ip-output");
    let rtspPortOutput = $(".rtsp-port-output");
    let rtspUsernameOutput = $(".rtsp-username-output");
    let rtspPasswordOutput = $(".rtsp-password-output");

    let apiUrlOutput = $(".api-url-output");
    let apiIpOutput = $(".api-ip-output");
    let apiPortOutput = $(".api-port-output");
    let apiUsernameOutput = $(".api-username-output");
    let apiPasswordOutput = $(".api-password-output");

    let elite2OutputLicense = $(".elite2-output-license");

    let hostnameInput = $("#hostname-input");
    let publicIpInput = $("#public-ip-input");
    let privateIpInput = $("#private-ip-input");
    let httpsPortInput = $("#https-port-input");
    let dexIdInput = $("#dex-id-input");
    let dexSecretInput = $("#dex-secret-input");
    let rtpPortInput = $("#rtp-port-input");
    let natsPortInput = $("#nats-port-input");
    let elite2InputLicense = $("#elite2-input-license");

    let publicIpLabel = $("#public-ip-label");
    let portForwardingSection = $(".port-forwarding-section");
    let httpChallengeSection = $(".http-challenge-section");

    let rtspHide = $(".rtsp-hide");

    //VPN
    let hostnameInputVpn = $("#hostname-input-vpn");
    //RTSP
    let rtspIpInput = $("#rtsp-ip-input");
    let rtspPortInput = $("#rtsp-port-input");
    let rtspUsernameInput = $("#rtsp-username-input");
    let rtspPasswordInput = $("#rtsp-password-input");

    let apiIpInput = $("#api-ip-input");
    let apiPortInput = $("#api-port-input");
    let apiUsernameInput = $("#api-username-input");
    let apiPasswordInput = $("#api-password-input");

    hostnameOutput.text(hostname);
    publicIpOutput.text(unknownIp);
    privateIpOutput.text(unknownIp);
    rtpPortOutput.text(rtpPort);
    natsPortOutput.text(natsPort);
    dexIdOutput.text(dexId);
    dexSecretOutput.text(dexSecret);

    //VPN
    hostnameOutputVpn.text(hostnameVpn);
    //RTSP
    rtspIpOutput.text(unknownIp);
    rtspPortOutput.text(rtspHttpPort);
    rtspUsernameOutput.text(rtspHttpUsername);
    rtspPasswordOutput.text(rtspHttpPassword);

    rtspPasswordInput.on("input", function() {
        let val = $(this).val();
        rtspPasswordOutput.text(val);

        if (val.length === 0 || val === rtspHttpPassword){
            rtspPasswordOutput.text(rtspHttpPassword);
        }
    });

    rtspUsernameInput.on("input", function() {
        let val = $(this).val();
        rtspUsernameOutput.text(val);

        if (val.length === 0 || val === rtspHttpUsername){
            rtspUsernameOutput.text(rtspHttpUsername);
        }
    });

    rtspPortInput.on("input", function() {
        let val = $(this).val();
        rtspPortOutput.text(val);

        if (val.length === 0 || val === rtspHttpPort){
            rtspPortOutput.text(rtspHttpPort);
        }
    });

    rtspIpInput.on("input", function() {
        let val = $(this).val();
        rtspIpOutput.text(val);

        if (val.length === 0 || val === unknownIp){
            rtspIpOutput.text(unknownIp);
        }
    });

    apiPasswordInput.on("input", function() {
        let val = $(this).val();
        apiPasswordOutput.text(val);

        if (val.length === 0 || val === rtspHttpPassword){
            apiPasswordOutput.text(rtspHttpPassword);
        }
    });

    apiUsernameInput.on("input", function() {
        let val = $(this).val();
        apiUsernameOutput.text(val);

        if (val.length === 0 || val === rtspHttpUsername){
            apiUsernameOutput.text(rtspHttpUsername);
        }
    });

    apiPortInput.on("input", function() {
        let val = $(this).val();
        apiPortOutput.text(val);

        if (val.length === 0  || val === rtspHttpPort){
            apiPortOutput.text(rtspHttpPort);
        }
    });

    apiIpInput.on("input", function() {
        let val = $(this).val();
        apiIpOutput.text(val);

        if (val.length === 0  || val === unknownIp){
            apiIpOutput.text(unknownIp);
        }
    });

    hostnameInputVpn.on("input", function() {
        let val = $(this).val();
        hostnameOutputVpn.text(val);

        if (val.length === 0  || val === hostnameVpn){
            hostnameOutputVpn.text(hostnameVpn);
        }
    });

    elite2InputLicense.on("input", function() {
        let val = $(this).val();
        elite2OutputLicense.text(val);

        if (val.length === 0  || val === licenseKey){
            elite2OutputLicense.text(licenseKey);
        }
    });

    hostnameInput.on("input", function() {
        let val = $(this).val();
        hostnameOutput.text(val);

        if (val.length === 0 || val === hostname){
            hostnameOutput.text(hostname);
        }
    });

    publicIpInput.on("input", function() {
        let val = $(this).val();

        publicIpOutput.text(val);
        if(portForwardingDisabledBtn.is(":checked")){
            privateIpOutput.text(val);
        }

        if (val.length === 0  || val === unknownIp){
            publicIpOutput.text(unknownIp);
            if(portForwardingDisabledBtn.is(":checked")){
                privateIpOutput.text(unknownIp);
            }
        }
    });

    privateIpInput.on("input", function() {
        let val = $(this).val();
        privateIpOutput.text(val);

        if (val.length === 0 || val === unknownIp){
            privateIpOutput.text(unknownIp);
        }
    });

    httpsPortInput.on("input", function() {
        let val = $(this).val();

        httpsPortOutput.text(":"+val);
        httpsPortNginxOutput.text(val);

        if (val.length === 0 || val === httpsPort){
            httpsPortOutput.text("");
            httpsPortNginxOutput.text(httpsPort);
        }
    });

    dexIdInput.on("input", function() {
        let val = $(this).val();
        dexIdOutput.text(val);

        if (val.length === 0 || val === dexId){
            dexIdOutput.text(dexId);
        }
    });

    dexSecretInput.on("input", function() {
        let val = $(this).val();
        dexSecretOutput.text(val);

        if (val.length === 0  || val === dexSecret){
            dexSecretOutput.text(dexSecret);
        }
    });

    rtpPortInput.on("input", function() {
        let val =  $(this).val();
        rtpPortOutput.text(val);

        if (val.length === 0 || val === rtpPort){
            rtpPortOutput.text(rtpPort);
        }
    });

    natsPortInput.on("input", function() {
        let val =  $(this).val();
        natsPortOutput.text(val);
        natsChangePortConfigFile.fadeIn("slow")

        if (val === "4243"){
            dontUse4243.addClass("text-danger")
            dontUse4243.addClass("animate-flicker")
        }else{
            dontUse4243.removeClass("text-danger")
            dontUse4243.removeClass("animate-flicker")
        }

        if (val.length === 0 || val === natsPort){
            natsPortOutput.text(natsPort);
            natsChangePortConfigFile.fadeOut("slow")
        }
    });

    portForwardingEnabledBtn.on("click", function() {
        portForwardingSection.fadeIn("slow");
        httpChallengeSection.fadeOut("slow")
        publicIpLabel.text("Router's public IP:");

        if(privateIpInput.val()){
            privateIpOutput.text(privateIpInput.val());
        }else{
            privateIpOutput.text(unknownIp);
        }
    });

    portForwardingDisabledBtn.on("click", function() {
        portForwardingSection.fadeOut("slow");
        httpChallengeSection.fadeIn("slow")
        publicIpLabel.text("Server's public IP:");

        if(publicIpInput.val()){
            privateIpOutput.text(publicIpInput.val());
        }else{
            privateIpOutput.text(unknownIp)
        }
    });

    rtspCredentialsEnabledBtn.on("click", function() {
        rtspHide.fadeIn("slow");
    });

    rtspCredentialsDisabledBtn.on("click", function() {
        rtspHide.fadeOut("slow");
    });

    batteryApiUrlBtn.on("click", function() {
        apiUrlOutput.text("battery.json");
    });

    gpsApiUrlBtn.on("click", function() {
        apiUrlOutput.text("gps.json");
    });

    statusApiUrlBtn.on("click", function() {
        apiUrlOutput.text("status.json");
    });


    portForwardingDisabledBtn.click();
    rtspCredentialsEnabledBtn.click();
    batteryApiUrlBtn.click();

    let simple = "filter-simple";
    let advanced = "filter-advanced";
    let sales = "filter-sales";
    let all_sections = [simple, advanced, sales]

    let presets = [["#simple", simple], ["#advanced", advanced], ["#sales", sales]]
    presets.forEach((preset) => {
        let btn = preset[0]
        let should_be_visible = preset[1]
        $(btn).on("click", function() {
            let should_be_hidden = new Set(all_sections)
            should_be_hidden.delete(should_be_visible)
            $('.'+should_be_visible+':not(visible)').each((idx, el) => {
                $(el).fadeIn('slow')
                $(el.href.substr(el.href.indexOf('#'))).fadeIn('slow')
            }).fadeIn('slow')
            should_be_hidden.forEach(v => {
                $('.'+v).each((idx, el) => {
                    if (!$(el).is("."+should_be_visible)) {
                        $(el).fadeOut('slow')
                        $(el.href.substr(el.href.indexOf('#'))).fadeOut('slow')
                    }
                })
            })
        });
    })
    $("#advanced").click();

    let collapsableBtn = $(".collapsable-btn")
    collapsableBtn.parent().next().slideUp();
    collapsableBtn.click(function (e) {
        if (!$(this).parent().next().is(":visible")) {
            $(this).parent().next().slideDown();
            $(this).removeClass('fa-plus').addClass('fa-minus');
        }else{
            $(this).parent().next().slideUp();
            $(this).removeClass('fa-minus').addClass('fa-plus');
        }
        e.preventDefault();
        e.stopPropagation();
    })

    $(".collapsable-title").click(function () {
        if(!$(this).hasClass("active")){
            if (!$(this).next().is(":visible")) {
                $(this).next().slideDown();
                $(this).children(":first").removeClass('fa-plus').addClass('fa-minus');
            }
        }
    })

    $("#rtsp-expand-menu").click(function () {
        let rtspMenu = $("#protocols-collapse");
        if(!rtspMenu.hasClass("active")){
            if (!rtspMenu.next().is(":visible")) {
                rtspMenu.next().slideDown();
                rtspMenu.children(":first").removeClass('fa-plus').addClass('fa-minus');
            }
        }
    })

    $("#darkMode1").click(function () {
        $("#darkmode").attr("href", "")
    })

    $("#darkMode2").click(function () {
        $("#darkmode").attr("href", "/css/dark-mode-dracula.css")
    })

    $("#darkMode3").click(function () {
        $("#darkmode").attr("href", "/css/dark-mode-gruvbox.css")
    })


    const navNav = document.getElementById("navigation-sidebar");
    const sections = [...document.querySelectorAll("section")];
    const navItem = (entries, observer) => {
        entries.forEach((entry) => {
            const { id } = entry.target;
            const nav = navNav.querySelector(`[href="#${id}"`);

            nav.classList.remove("active");
            if (!entry.isIntersecting) return;
            nav.classList.add("active");
            //nav.parentElement.previousElementSibling.classList.add("active");
        });
    };
    const observer = new IntersectionObserver(navItem, {
        //rootMargin: "-10% 0% -90% 0%",
        rootMargin: "-15% 0% -85% 0%",
        root: document
        // <- This is only here to force the iframe document.
    });
    sections.forEach((section) => observer.observe(section));

    var historyArray = [];

    $(".nav-link-pd").on("click", function() {
        if(!$(this).hasClass( "active" )){
            let hr = $("#navigation-sidebar").find(".active").attr("href");
            if(hr){
                historyArray.push(hr);
            }
        }
    });

    $(".anchor-link").on("click", function() {
        let hr = $("#navigation-sidebar").find(".active").attr("href");
        if(hr){
            historyArray.push(hr);
        }
    });

    $("#history-array").on("click", function() {
        let popped = historyArray.pop();
        if(popped){
            window.location.href = popped;
        }else{
            window.location.href = "#";
        }
    });

});